<template>
  <div class="data-radius" :style="{borderRadius: '4px'}">
    <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="24" class="line-center">
        <el-text class="mx-1 line-center-value" type="primary">{{getSimExpire()}}</el-text>
      </el-col>
    </el-row>
    <el-row class="card-row" :gutter="20" align="middle">
      <el-col :span="24" class="line-center">
        <el-text class="mx-1">{{getI18n('SIM卡过期时间', 'SIM Expire Time')}}</el-text>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, defineProps } from 'vue'
import { getI18n, convertTimestamp } from '../utils'
export default defineComponent({
  name: 'IccidCard'
})
</script>

<script lang="ts" setup>
const props = defineProps({
  iccidInfoResp: {
    type: Object,
    require: true
  }
})
function getSimExpire () {
  return convertTimestamp(props.iccidInfoResp.himsis[0].expireTime)
}
</script>

<style scoped>
.line-center {
  text-align: center;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.line-center-value {
  font-size: 32px;
}
</style>

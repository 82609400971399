<template>
  <div class="div-margin-top">
    <el-image :src="'./images/banner01.png'"/>
    <ul align>
      <li> {{getI18n("到达目的地套餐自动激活", 'Automatic activation of package upon arrival at destination')}}</li>
      <li> {{getI18n("请打开漫游", 'Please open roaming')}}</li>
      <li> {{getI18n("APN 是 cmlink", 'APN is cmlink')}}</li>
      <li> {{getI18n("SIM卡的有效期是365天，每次使用都会顺延365天", "Extend SIM card validity of 365 days when use any data or order new package once.")}}</li>
      <li> {{getI18n("支持100多个国家，一卡走遍世界", "Supports over 100 countries; one card for the world.")}}</li>
      <li> <el-link type="primary" :href="'/helper/install/android'">{{getI18n("安卓安装ESIM", 'Installing ESIM on Android')}}</el-link></li>
      <li> <el-link type="primary" :href="'/helper/install/apple'">{{getI18n("苹果安装ESIM", 'Installing ESIM on Apple')}}</el-link></li>
      <li> <el-link type="primary" :href="'/helper/support-device'">{{getI18n("适用机型", 'Compatible Devices')}}</el-link></li>
      <li> <el-link type="primary" href="/helper/faq/zh">{{getI18n("FAQ", 'FAQ')}}</el-link></li>
      <li> <el-link type="primary" href="https://global.cmlink.com/store/realname">{{getI18n("香港、台湾需要实名认证", 'Hong Kong and Taiwan require real-name registration')}}</el-link></li>
    </ul>
</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { getI18n } from '../utils'
export default defineComponent({
  name: 'HelpCenter'
})
</script>

<script lang="ts" setup>
onMounted(async () => {
  console.log('enter')
})
</script>

<style scoped>
.div-margin-top {
  margin-top: 10px;
}
li {
  margin-bottom: 10px;
}
</style>

<template>
  <div class="radius card bottom10px" :style="{borderRadius: '4px', padding:'1px'}">
    <el-tag :key="plan.order_id" class="auto-wrap" effect="light" >
      <el-text class="mx-1 value-font" type="primary">{{plan.bundle_name}}</el-text>
    </el-tag>
    <template v-if="plan.status=='未激活'">
      <el-row class="card-row" :gutter="20" align="middle">
          <el-col :span="9" class="col-title">
            <el-text class="mx-1 ">{{getI18n('订单开始时间', 'Order Start Time')}}:</el-text>
          </el-col>
          <el-col :span="15">
            <el-text class="mx-1" type="primary">{{convertUTC2LocalTime(plan.create_time)}}</el-text>
          </el-col>
      </el-row>
      <el-row class="card-row" :gutter="20" align="middle">
          <el-col :span="9" class="col-title">
            <el-text class="mx-1">{{getI18n('订单结束时间', 'Order End Time')}}:</el-text>
          </el-col>
          <el-col :span="15">
            <el-text class="mx-1" type="primary">{{convertUTC2LocalTime(plan.end_time)}}</el-text>
          </el-col>
      </el-row>
      <el-row class="card-row" :gutter="20" align="middle">
          <el-col :span="9" class="col-title"></el-col>
          <el-col :span="15">
            <span class="font-red">{{getI18n('最晚激活时间', 'Latest Activation Time')}}</span>
          </el-col>
      </el-row>
    </template>
    <template v-if="plan.status!='未激活'">
      <el-row class="card-row" :gutter="20" align="middle">
          <el-col :span="9" class="col-title">
            <el-text class="mx-1">{{getI18n('激活时间', 'Active Time')}}:</el-text>
          </el-col>
          <el-col :span="15">
            <el-text class="mx-1" type="primary">{{convertUTC2LocalTime(plan.active_time)}}</el-text>
          </el-col>
      </el-row>
      <el-row class="card-row" :gutter="20" align="middle">
          <el-col :span="9" class="col-title">
            <el-text class="mx-1">{{getI18n('过期时间', 'Expire Time')}}:</el-text>
          </el-col>
          <el-col :span="15">
            <span class="font-red">{{convertUTC2LocalTime(plan.expire_time)}}</span>
          </el-col>
      </el-row>
    </template>
    <el-row class="card-row" :gutter="20" align="middle">
        <el-col :span="9" class="col-title">
          <el-text class="mx-1">{{getI18n('状态', 'Status')}}:</el-text>
        </el-col>
        <el-col :span="6">
          <el-text class="mx-1 value-font" type="primary">{{convertPackageStatus(plan)}}</el-text>
        </el-col>
        <el-col :span="9" class="col-button" v-if="plan.status!='未激活'">
          <el-button plain @click="queryDataByPackage(plan.order_id)">
            <span type="success">{{getI18n('查询流量', 'Query Data')}}</span>
            <el-icon><ArrowDown v-if="!refIsExpand"/><ArrowUp v-if="refIsExpand" /></el-icon>
          </el-button>
        </el-col>
    </el-row>
    <!-- 第二层每个套餐的详情， 包括流量使用情况 -->
    <template v-if="refIsExpand && !isNullValue(plan.quota) && plan.quota.code == '0000000'">
      <PacakgeDataCard :subscriberQuota="plan.quota.subscriberQuota"/>
      <PackageDataDetailCard :historyQuota="plan.quota.historyQuota"/>
    </template>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, defineProps } from 'vue'
import { ArrowDown, ArrowUp } from '@element-plus/icons-vue'
import store from '../store'
import { apiPostData, isNullValue, getI18n, convertUTC2LocalTime, getLocalTime } from '../utils'
import PacakgeDataCard from './PacakgeDataCard.vue'
import PackageDataDetailCard from './PackageDataDetailCard.vue'
export default defineComponent({
  name: 'PackageCard'
})
</script>

<script lang="ts" setup>
// eslint-disable-next-line
const props = defineProps({
  plan: {
    type: Object,
    require: true
  }
})

function convertPackageStatus (plan: object) {
  if (plan.status === '未激活') {
    if (getLocalTime() > convertUTC2LocalTime(plan.end_time)) {
      return getI18n('订单已过期', 'Order Expired')
    }
    return getI18n('未激活', 'Unuse')
  } else if (plan.status === '已激活') {
    return getI18n('已激活', 'Using')
  } else if (plan.status === '已结束') {
    return getI18n('已结束', 'Used')
  } else {
    return plan.status
  }
}

const refIsExpand = ref(false)
function queryDataByPackage (orderId: string) {
  refIsExpand.value = !refIsExpand.value
  if (!refIsExpand.value) {
    return
  }
  const data = { order_id: orderId }
  apiPostData('/cmi/APP_getOrderSubscriberAllQuota_SBO/v1?status=1', data)
    .then(data => {
      if (data !== null && data !== undefined) {
        for (let i = 0; i < store.state.packageResp.data.length; i++) {
          if (store.state.packageResp.data[i].order_id !== orderId) {
            continue
          }
          store.state.packageResp.data[i].quota = data
        }
      }
    })
}
</script>

<style scoped>
.auto-wrap {
  width: 100%; /* 设置标签宽度 */
  white-space: normal; /* 允许换行 */
  height: auto;
  margin-bottom: 10px;
  font-size: 28px;
}
.col-button {
  text-align: right;
  padding-right: 20px !important;
}
</style>
